<template>
	<div class="main" :class="show ? 'h5' : ''">
		<div class="htop">
		<mtop />
		</div>
		<div class="banner">
				<img
            src="../assets/ximg/banner-szzc@2x.png"
            alt="szzc"
            class="simg"
          />
		</div>
		<div class="content">
            <div class="box">
                <div class="btop">
				<div class="title">我们能为您提供</div>
				<div class="tips">星科钱包是一款WEB3数字资产钱包产品</div>
				</div>
                <div class="cont">
                    <div class="left">
                    <div class="item" :class="type==1?'act':''" @click="toChange(1)">数字钱包管理</div>
                    <div class="item" :class="type==2?'act':''" @click="toChange(2)">聚合跨链交易</div>
                    <div class="item" :class="type==3?'act':''" @click="toChange(3)">NFT市场</div>
                    <!-- <div class="item" :class="type==4?'act':''" @click="toChange(4)">加密社交</div> -->
                    <div class="item" :class="type==5?'act':''" @click="toChange(5)">DAPP应用商店</div>
                    <div class="item" :class="type==6?'act':''" @click="toChange(6)">Launchpad（项目孵化）</div>
                </div>
                    <div class="right">
                        <div class="img ">
						<img src="@/assets/ximg/wntg-1@2x.png" class="fadeInRight" v-if="type==1"/>
                        <img src="@/assets/ximg/wntg-2@2x.png" class="fadeInRight" v-if="type==2"/>
                        <img src="@/assets/ximg/wntg-3@2x.png" class="fadeInRight" v-if="type==3"/>
                        <!-- <img src="@/assets/ximg/wntg-4@2x.png" class="fadeInRight" v-if="type==4"/> -->
                        <img src="@/assets/ximg/wntg-5@2x.png" class="fadeInRight" v-if="type==5"/>
                        <img src="@/assets/ximg/wntg-6@2x.png" class="fadeInRight" v-if="type==6"/>

					</div>
                    </div>
                </div>
            </div>
		</div>

        <div class="content bg">
            <div class="box">
                <div class="btop">
				<div class="title">产品特点</div>
				<div class="tips">星科钱包是一款WEB3数字资产钱包产品</div>
				</div>
                <div class="lint">
                    <div class="card">
                    <img src="@/assets/ximg/wellet-1@2x.png" class="ibg"/>
                    <div class="header">安全保障</div>
                    <div class="cbox">通过多重签名、冷热钱包分离、风控系统等，安全保障措施，提高用户数字资产的安全性。</div>
                    </div>
                    <div class="card flex">
                    <img src="@/assets/ximg/wellet-2@2x.png" class="ibg"/>
                    <div class="header">加密社交</div>
                    <div class="cbox">支持用户P2P加密消息传输,可以自定义时间自毁消息,实现更私密、更安全的社交需求。</div>
                    </div>
                    <div class="card">
                    <img src="@/assets/ximg/wellet-3@2x.png" class="ibg"/>
                    <div class="header">智能化操作</div>
                    <div class="cbox">接入ChatGPT提升智能化操作流程和个性化服务，提高用户使用的便捷性和体验。</div>
                    </div>
                </div>
                <div class="lint">
                    <div class="card">
                    <img src="@/assets/ximg/wellet-4@2x.png" class="ibg"/>
                    <div class="header">多客户端</div>
                    <div class="cbox">支持移动端APP和PC端等多端应用,满足用户在不同设备上的使用需求.</div>
                    </div>
                    <div class="card ">
                    <img src="@/assets/ximg/wellet-5@2x.png" class="ibg"/>
                    <div class="header">生态通证</div>
                    <div class="cbox">通过发行自己的代币经济模型，激励用户参与生态建设，提高用户黏性。</div>
                    </div>
                    <div class="card">
                    <img src="@/assets/ximg/wellet-6@2x.png" class="ibg"/>
                    <div class="header">跨链交易</div>
                    <div class="cbox">不仅支持数字货币之间的swap聚合交易，还支持跨链资产的兑换,方便用户管理数字资产。</div>
                    </div>
                    <div class="card">
                    <img src="@/assets/ximg/wellet-7@2x.png" class="ibg"/>
                    <div class="header">多链多币种</div>
                    <div class="cbox">支持市场全部主流区块链网络和数字资产，提高用户的选择和满足不同链用户的需求。</div>
                    </div>
                </div>
            </div>
        </div>

		<div class="save_box4">
			<h1 class="box4_title">定制服务流程</h1>
			<h2 class="box4_tip">按需定制，快速响应客户定制需求</h2>
			<div class="box4_bot">
				<div class="line">
					<div class="box_item">
						<img
							src="../assets/ximg/icon-xqdy.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">需求调研</div>
						<div class="tip2">01</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-xqfx.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">需求分析</div>
						<div class="tip2">02</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-yxsj.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">原型设计</div>
						<div class="tip2">03</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-cpsj.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">产品设计</div>
						<div class="tip2">04</div>
					</div>
				</div>
				<div class="bank">
					<div class="zzimg">
						<img src="../assets/ximg/jt.png" alt="" />
					</div>
				</div>
				<div class="line resco">
					<div class="box_item">
						<img
							src="../assets/ximg/icon-xqdy.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">界面设计</div>
						<div class="tip2">05</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-xqfx.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">程序编码</div>
						<div class="tip2">06</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-yxsj.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">软件测试</div>
						<div class="tip2">07</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-cpsj.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">打包发布</div>
						<div class="tip2">08</div>
					</div>
				</div>
			</div>
			<div class="bottom">
				<div class="btn" @click="toPage('/customizing')">立即定制</div>
			</div>
		</div>

		<mbottom />
	</div>
</template>

<script>
	import Swiper from "swiper";
	import "swiper/swiper.css";
	import mtop from "../components/top.vue";
	import mbottom from "../components/bottom.vue";
	export default {
		data() {
			// this.$router.replace({ path: "X3matrix" });
			return {
				current: 0,
                type:1,
				autoplay: true,
				index: 0,
				banbox: [],
				problem: [],
				umobile: "",
				buy: true,
				buynum: 1,
				show: false,
				shows: false,
				shloading: false,
				sqloading: false,
				list: [
					{
						title: "网站建设周期需要多久？",
						description:
							"一般做个企业官网建设，周期为10-15个工作日，功能定制型网站，根据定制要求，核算交付周期。",
					},
					{
						title: "网站建设不满意怎么办？",
						description:
							"网站设计师按要求出设计稿，初稿不满意，可根据贵司的要求修改，并且不限制修改次数，直到满意为止！",
					},
					{
						title: "你们可以移交源代码嘛？",
						description:
							"可以的，我司在核实完您的信息之后，承诺随时提供网站建设源码和FTP信息，FTP信息很重要，请您妥善保管。",
					},
					{
						title: "外地企业怎么和你们沟通?",
						description:
							"可以通过QQ、微信、电话、视频会议等进行沟通，无需担心，不管本地及外地，项目进行中都是在线沟通的！",
					},
					{
						title: "已经有域名了，需要提供给你们什么？",
						description:
							"您只需要提供域名解析权限或管理密码就可以，我公司技术会帮您解析好IP地址，完善服务器信息！",
					},
					{
						title: "产品图片是你们帮我处理吗？",
						description:
							"我公司会帮您处理产品图（20张以内），banner图设计3-5张，如有特殊需要，请联系一诺客服！",
					},
					{
						title: "企业网站建设要多少钱？",
						description:
							"一般企业网站建设而言，如果只是展示宣传，无特殊功能，1万五千元左右，如有特殊定制，功能需求等需另行核算。",
					},
					{
						title: "你们能做网站优化推广吗？",
						description:
							"SEO是重要的引流方式，我们有多种推广方式，网站优化，竞价排名，SEM等供您选择，少花钱多办事儿！",
					},
					{
						title: "网站、APP开发有合同吗？",
						description:
							"签订正规网站建设合同，开具发票，诚信合作。",
					},
					{
						title: "是否有其他隐形费用?",
						description:
							"重合同、守承诺，网站建设期间无任何隐形费用。",
					},
				],
			};
		},
		focus: {
			inserted: function (el, { value }) {
				console.log(el, { value });
				if (value) {
					el.focus();
				}
			},
		},

		components: {
			mtop,
			mbottom,
		},

		mounted() {
			if (this._isMobile()) {
				this.show = true;
			} else {
				this.show = this.$store.getters.phone;
			}
			this.initCase();
		},
		watch: {
			"$store.getters.phone": function (val) {
				this.show = val;
			},
			deep: true,
			immediate: true,
		},
		created() {
			this.getinfo();
		},
		methods: {
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			},
            toChange(e){
                this.type=e
            },
			outleft() {
				this.autoplay = true;
			},
			moveleft(val) {
				console.log(val);
				this.autoplay = false;
				this.index = val;
			},
			changeban(val) {
				console.log(val);
				this.index = val;
			},
			kefu() {
				//   console.log(1111);
				//    window._MICHAT = window._MICHAT || function () { (_MICHAT.a = _MICHAT.a || []).push(arguments) };
				// _MICHAT("accountid", 127020);
				// _MICHAT("domain", "mamkf.kanuomei.com");
				// (function (m, d, q, j, s) {
				//     j = d.createElement(q),s = d.getElementsByTagName(q)[0];
				//     j.async = true;
				//     j.charset ="UTF-8";
				//     j.src = ("https:" == document.location.protocol ? "https://" : "http://") + "mamkf.kanuomei.com/Web/JS/mivisit.js?_=t";
				//     s.parentNode.insertBefore(j, s);
				// })(window, document, "script");
			},
			toPage(row) {
				this.$router.push(row);
			},
			getprice() {
				if (this.umobile == "") {
					this.$toast("请填写正确手机号!");
					return false;
				} else {
					this.$axios({
						method: "post",
						url: "/v1/apply",
						params: { mobile: this.umobile },
					})
						.then((res) => {
							console.log(res);
							if (res.data.code == 200) {
								this.$toast("成功提交");
								this.umobile = "";
							} else {
								this.$toast(res.data.msg);
							}
						})
						.catch((resp) => {
							this.$toast(resp.data.msg);
						});
				}
			},

			getinfo() {
				//   this.$axios({
				//     method:'get',
				//     url:'/v1/articleList',
				//     params:{type:2,cate_id:1,is_hot:1},
				// }).then((res)=>{
				//     this.list=res.data.data.list.data;
				// }).catch(resp => {
				//     console.log('请求失败');
				// });
			},
			to(e) {
				// this.$router.replace({ path: e });
				location.href = e;
			},
			tobuy() {
				location.href =
					"https://opensea.io/collection/cupid-love-metaverse";
			},

			close() {
				this.shows = false;
			},
			initCase() {
				//一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
				new Swiper("#case", {
					loop: false,
					breakpoints: {
						320: {
							//当屏幕宽度大于等于320
							slidesPerView: 4,
						},
						750: {
							//当屏幕宽度大于等于768
							slidesPerView: 5,
						},
					},
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	$t-mf: "Arial";
	.case-tab {
		display: flex;
		justify-content: center;
		border-bottom: 1px solid #dcdee0;
		.case-nav {
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			color: #646566;
			padding: 10px 0;
			cursor: pointer;
			&.active {
				font-size: 16px;
				font-weight: 500;
				color: #2b6bff;
				border-bottom: 1px solid #2b6bff;
			}
		}
	}
	.main {
		background: #fff;
		max-width: 100%;
		min-width: 1200px;
		width: 100%;
        .htop{
		    height: 70px;
	    }	
        .banner{
    width: 100%;
    font-size: 0;
    .simg{
        width: 100%;
        height: auto;
    }
  }
        .content{
            .box{
                width: 1200px;
					margin: 0 auto;
					padding: 86px 0;
                    .btop{
						display: flex;
						align-items: center;
						flex-direction: column;
						margin: 0px auto 62px;
						.title{
							font-size: 32px;
							font-weight: 500;
							color: #1D2129;
							line-height: 45px;
							margin-bottom: 16px;
						}
						.tips{
							font-size: 16px;
							font-weight: 400;
							color: #646566;
							line-height: 22px;
						}
					}
                    .cont{
                        display: flex;
                        align-items:center;
                        .left{
                            width: 450px;
                            .item{
                                width: 100%;
                            height: 73px;
                            display: flex;
                        align-items:center;
                        font-size: 18px;
                        cursor: pointer;
                        padding-left: 40px;
                        font-weight: 500;
                        color: #000000;
                        line-height: 25px;
                           
                            }
                            .act{
                                background: #F2F9FF;
                                position: relative;                                
                                color: #2B6BFF;
                            }
                            .act::before{
                                content: "";
                                position: absolute;
                                width: 4px;
                                height: 100%;
                                left: 0;
                                background: #2B6BFF;
                            }
                        }
                        .right{
                            flex: 1;
                            display: flex;
                            justify-content: flex-end;
                            .img{
                                width: 446px;
                                height: 390px;
                                img{
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }

                    .lint{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        .card{
                            width: 292px;
                            height: 224px;
                            position: relative;
                            z-index: 1;
                            padding: 24px;
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: column;
                            .ibg{
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                left: 0;
                                top: 0;
                                z-index: -1;
                            }
                            .header{
                                font-size: 24px;
                                font-weight: 500;
                                color: #FFFFFF;
                                line-height: 33px; 
                                border-bottom: 1px solid #ffffff52;
                                padding-bottom: 16px;
                            }
                            .cbox{
                                margin-top: 16px;
                                font-size: 14px;
                                font-weight: 400;
                                color: #FFFFFF;
                                line-height: 21px;
                            }
                        }
                        .flex{
                            flex: 1;
                            margin: 0 10px;
                        }
                    }
            }
        }
        .bg{
            background: url(~@/assets/ximg/background-chanpin@2x.png) no-repeat;
			background-size: cover;
			background-position: center center;
        }
		.save_box1 {
			cursor: default;
			width: 100%;
			padding: 90px 0;
			background: #f4f6fe;
			display: flex;
			height: 702px;
			box-sizing: border-box;
			flex-direction: column;
			text-align: center;
			.box1_title {
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				margin: 0px;
			}
			.box1_tip {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				margin: 0px;
				line-height: 50px;
			}
			.box1_bottom {
				display: flex;
				justify-content: space-between;
				width: 1200px;
				margin: 36px auto;
				.card {
					width: 363px;
					padding: 50px 0;
					height: 216px;
					background: #ffffff;
					border-radius: 2px;
					display: flex;
					flex-direction: column;
					align-items: center;
					overflow: hidden;
					.img {
						img {
							width: 90px;
							height: 90px;
						}
						margin-bottom: 35px;
					}
					.title {
						font-size: 24px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #1d2129;
						line-height: 33px;
						margin-bottom: 16px;
					}
					.conttxt {
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #969799;
					}
					.btn {
						font-size: 16px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #2b6bff;
						line-height: 40px;
						width: 112px;
						height: 40px;
						background: #ffffff;
						border-radius: 2px;
						border: 1px solid #2b6bff;
						margin-top: 50px;
						display: none;
					}
				}
				.card:hover {
					box-shadow: 0px 6px 16px 0px rgba(62, 73, 96, 0.15);
					border-radius: 2px;
					height: auto;
					transition: all 0.8s ease-in;
					.btn {
						display: block;
						cursor: pointer;
					}
				}
			}
		}
		.save_box2 {
			cursor: default;
			// width:1920px;
			min-height: 626px;
			background: #ffffff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 90px 0;
			width: 1200px;
			margin: 0 auto;
			.box2_title {
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				margin: 0 auto;
				width: 100%;
				text-align: center;
			}
			.box2_tip {
				width: 100%;
				margin: 0 auto;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				text-align: center;
				line-height: 50px;
			}
			.box2_bot {
				display: flex;
				width: 1200px;
				margin: 20px auto;
				justify-content: space-between;
				flex-direction: column;
				.bot_item {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					background: #ffffff;
					box-shadow: 0px 6px 16px 0px rgba(62, 73, 96, 0.15);
					border-radius: 2px;
					margin: 22px 0;
					padding: 12px 73px;
					box-sizing: border-box;
					.left {
						img {
							width: 433px;
							height: auto;
						}
					}
					.right {
						width: 476px;
						.title {
							font-size: 24px;
							font-weight: 500;
							color: #1d2129;
							line-height: 33px;
							text-align: left;
							margin: 30px 0;
							position: relative;
							&::after {
								content: "";
								width: 22px;
								height: 4px;
								background: #2b6bff;
								border-radius: 2px;
								position: absolute;
								left: 0;
								bottom: -15px;
							}
						}
						.sinfo {
							font-size: 14px;
							font-weight: 400;
							color: #969799;
							text-align: left;
						}
					}
				}
				.bot_item:nth-child(2n) {
					flex-direction: row-reverse;
				}

				.item2 {
					.item_head {
						width: 100%;
						height: 87px;
						text-align: center;
						background: linear-gradient(
							264deg,
							#fee2cd 0%,
							#fff2e7 100%
						);
						box-sizing: border-box;
						padding: 20px;

						div {
							color: #8b3b34;
						}
						div:nth-child(1) {
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #8b3b34;
							line-height: 30px;
						}
						div:nth-child(2) {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #8b3b34;
							line-height: 20px;
						}
					}
					.item_con {
						box-sizing: border-box;
						padding: 20px;
						.con_item {
							display: flex;
							justify-content: space-between;
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #000000;
							line-height: 30px;
							span {
								color: #8b3b34;
								font-size: 18px;
							}
						}
					}
					.item_bot {
						width: 186px;
						height: 41px;
						background: #8b3b34;
						border-radius: 4px;
						text-align: center;
						margin: 0 auto;
						line-height: 41px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						span {
							font-size: 10px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #ffffff;
							line-height: 20px;
						}
					}
				}

				.item3 {
					.item_head {
						width: 100%;
						height: 87px;
						text-align: center;
						background: linear-gradient(
							92deg,
							#eff8ff 0%,
							#d0e7f9 100%
						);
						box-sizing: border-box;
						padding: 20px;

						div {
							color: #0c63d7;
						}
						div:nth-child(1) {
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;

							line-height: 30px;
						}
						div:nth-child(2) {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							line-height: 20px;
						}
					}
					.item_con {
						box-sizing: border-box;
						padding: 20px;
						.con_item {
							display: flex;
							justify-content: space-between;
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #000000;
							line-height: 30px;
							span {
								color: #0c63d7;
								font-size: 18px;
							}
						}
					}
					.item_bot {
						width: 186px;
						height: 41px;
						background: #ffffff;
						border: 1px solid #0c63d7;
						color: #0c63d7;
						border-radius: 4px;
						text-align: center;
						margin: 0 auto;
						line-height: 41px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #0c63d7;
						span {
							font-size: 10px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #0c63d7;
							line-height: 20px;
						}
					}
				}

				.item4 {
					.item_head {
						width: 100%;
						height: 87px;
						text-align: center;
						background: linear-gradient(
							264deg,
							#fec0ae 0%,
							#ffe0d7 100%
						);
						box-sizing: border-box;
						padding: 20px;
						color: #ff8460;
						div {
							color: #ff8460;
						}
						div:nth-child(1) {
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #8b3b34;
							line-height: 30px;
						}
						div:nth-child(2) {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #8b3b34;
							line-height: 20px;
						}
					}
					.item_con {
						box-sizing: border-box;
						padding: 20px;
						.con_item {
							display: flex;
							justify-content: space-between;
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #000000;
							line-height: 30px;
							span {
								font-size: 18px;
								color: #ff8460;
							}
						}
					}
					.item_bot {
						width: 186px;
						height: 41px;
						background: #ff8460;
						border-radius: 4px;
						text-align: center;
						margin: 0 auto;
						line-height: 41px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						a {
							color: #ffff;
							span {
								font-size: 10px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #ffffff;
								line-height: 20px;
							}
						}
					}
				}
			}
		}
		.save_box3 {
			cursor: default;
			width: 100%;
			height: 578px;
			background: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.box3_title {
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				width: 1200px;
				margin: 0 auto;
			}
			.box3_con {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				width: 1200px;
				margin: 0 auto;
				line-height: 50px;
			}
			.box3_bot {
				width: 1200px;
				margin: 40px auto;
				padding: 0 30px;
				box-sizing: border-box;
				display: flex;
				justify-content: space-between;
				.bot_item {
					width: 234px;
					height: 300px;
					background: #ffffff;
					box-shadow: 0px 0px 21px 0px rgba(81, 81, 81, 0.19);
					border-radius: 4px;
					border-bottom: 3px solid #0fb3b4;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					box-sizing: border-box;
					padding: 0 30px;
					div:nth-child(1) {
						font-size: 26px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #0fb3b4;
						line-height: 30px;
					}
					div:nth-child(2) {
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 30px;
						margin-top: 40px;
						text-align: center;
					}
					div:nth-child(3) {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #7e8184;
						line-height: 30px;
						text-align: left;
						margin-top: 27px;
						text-align: center;
					}
				}
				.you1 {
					border-bottom: 3px solid #0fb3b4;
					div:nth-child(1) {
						font-size: 26px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #0fb3b4;
						line-height: 30px;
					}
				}
				.you2 {
					border-bottom: 3px solid #8b3b34;
					div:nth-child(1) {
						color: #8b3b34;
					}
				}
				.you3 {
					border-bottom: 3px solid #16d1f1;
					div:nth-child(1) {
						color: #16d1f1;
					}
				}
				.you4 {
					border-bottom: 3px solid #ff8460;
					div:nth-child(1) {
						color: #ff8460;
					}
				}
				.bot_item:hover {
					color: #fff !important;
					background: linear-gradient(0deg, #1a8eff 0%, #19d5f5 100%);
					box-shadow: 0px 0px 21px 0px rgba(26, 150, 254, 0.19);
					border-radius: 10px;
					transform: scale(1.1);
					div {
						color: #fff !important;
					}
				}
			}
		}
		.save_box4 {
			height: 589px;
			background: #f4f6fe;
			padding: 1px;
			width: 100%;
			margin: 0 auto;
			.box4_title {
				width: 100%;
				margin: 0 auto;
				text-align: center;
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				margin-top: 54px;
			}
			.box4_tip {
				width: 100%;
				margin: 0 auto;
				text-align: center;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				margin-top: 15px;
			}
			.box4_bot {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				width: 1200px;
				margin: 0 auto;
				margin-top: 25px;
				.line {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.jtico {
						width: 45px;
						height: 45px;
						margin: 0 11px;
					}
				}
				.resco {
					flex-direction: row-reverse;
					.jtico {
						transform: rotate(180deg);
					}
				}
				.bank {
					display: flex;
					justify-content: flex-end;
					width: 100%;
					.zzimg {
						width: 249px;
						height: 75px;
						display: flex;
						justify-content: center;
						align-items: center;
					}
					img {
						width: 45px;
						height: 45px;
						transform: rotate(90deg);
					}
				}
				.box_item {
					width: 249px;
					height: 75px;
					background: #ffffff;
					border-radius: 2px;
					display: flex;
					align-items: center;
					justify-content: center;
					box-sizing: border-box;
					position: relative;
					.img1 {
						width: 25px;
						height: 25px;
					}
					.tip1 {
						font-size: 18px;
						font-family: SourceHanSansCN-Regular, SourceHanSansCN;
						font-weight: 400;
						color: #1d2129;
						line-height: 27px;
						margin-left: 15px;
					}
					.tip2 {
						position: absolute;
						bottom: 0;
						right: 0;
						font-size: 50px;
						font-family: DINPro-Bold, DINPro;
						font-weight: bold;
						color: #f7f8fa;
						line-height: 65px;
					}
				}

				.box_item:hover {
					transform: scale(1.02);
					box-shadow: 0px 0px 0.28rem 0px rgb(0 0 0 / 19%);
				}
			}
			.bottom {
				width: 100%;
				display: flex;
				justify-content: center;
				margin: 59px 0;
				text-align: center;
				.btn {
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #ffffff;
					line-height: 40px;
					width: 112px;
					height: 40px;
					background: #2b6bff;
					border-radius: 2px;
					cursor: pointer;
				}
			}
		}
		.save_box5 {
			// max-height:520px;
			min-height: 300px;
			background: #fff;
			.box5_title {
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #020202;
				width: 1200px;
				margin: 0 auto;
				text-align: center;
				margin-top: 55px;
			}
			.box5-tip {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #020202;
				width: 1200px;
				margin: 0 auto;
				text-align: center;
				margin-top: 13px;
			}
		}
		.box5_look {
			width: 100px;
			height: 34px;
			background: #ffffff;
			border: 1px solid #e9e9e9;
			border-radius: 17px;
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #666666;
			line-height: 34px;
			margin: 0 auto;
			text-align: center;
			margin-bottom: 35px;
			// margin-top:-38px;
		}
		.box5_look:hover {
			background: linear-gradient(0deg, #1a8eff 0%, #16d1f1 100%);
			color: #fff;
		}
		.box5_bot {
			width: 1200px;
			margin: 0 auto;
			margin-top: 50px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.box5_item {
				width: 590px;
				height: 95px;
				background: #ffffff;
				border: 1px solid #e9e9e9;
				margin-bottom: 20px;
				box-sizing: border-box;
				padding: 15px;
				text-align: left;
				div:nth-child(1) {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #333333;
				}
				div:nth-child(2) {
					font-size: 12px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #666666;
					line-height: 16px;
					margin-top: 13px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
			.box5_item:hover {
				background: linear-gradient(0deg, #eee 0%, #fff 100%);
			}
		}
	}
	.h5 {
		width: 100%;
		min-width: 100%;
        .htop{
            height:125px;
        }
        .banner{
    width: 100%;    
    font-size: 0;
    display: flex;
        align-items: center;
        justify-content: center;
    .simg{
        width: 1000px;
        height: auto;
    }
  }
  .content{
    .box{
        width: 100%;
        .cont{
            .left{
                width: auto;
            }
        }
        .lint{
            flex-direction:column;
            .card{
                width: 80%;
                height: auto;
                min-height: 380px;
                margin: 12px;
                .header{
                    font-size: 32px;
                }
                .cbox{
                    font-size: 24px;
                    line-height: 38px;
                }
            }
        }
    }
  }
		.save_box1 {
			width: 100%;
			height: auto;
			text-align: center;
			.box1_title {
				font-size: 36px;
				font-weight: 600;
			}
			.box1_tip {
				font-size: 26px;
				margin: 20px auto;
			}
			.box1_bottom {
				flex-direction: column;
				width: 100%;
				.card {
					width: calc(100% - 100px);
					height: auto;
					margin: 20px auto;
					.img {
						img {
							width: 200px;
							height: 200px;
						}
					}
					.title {
						font-size: 32px;
					}
					.conttxt {
						line-height: 36px;
						font-size: 26px;
					}
					.btn {
						width: 200px;
						height: 68px;
						font-size: 30px;
						line-height: 68px;
					}
				}
			}
		}
		.save_box2 {
			width: 100%;
			.box2_title {
				font-size: 36px;
				font-weight: 600;
			}
			.box2_tip {
				font-size: 26px;
				margin: 20px auto;
			}
			.box2_bot {
				width: 100%;
				.bot_item {
					flex-direction: column;
					padding-bottom: 40px;
					.left {
						img {
							width: calc(100% - 60px);
							height: 100%;
							margin: 0 30px;
						}
					}
					.right {
						.title {
							font-size: 32px;
						}
						.sinfo {
							font-size: 26px;
						}
					}
				}
				.bot_item:nth-child(2n) {
					flex-direction: column;
				}
			}
		}
		.save_box4 {
			width: 100%;
			height: 100%;
			.box4_title {
				font-size: 36px;
				font-weight: 600;
			}
			.box4_tip {
				font-size: 26px;
				margin: 20px auto;
			}
			.box4_bot {
				width: 100%;
				margin: 60px auto;
				.line {
					flex-direction: column;
					width: 80%;
					margin: 0 auto;
					.box_item {
						width: 100%;
						flex-direction: column;
						height: 100%;
						padding: 20px;
						border-radius: 10px;
						.tip1 {
							margin-left: 0;
							font-size: 26px;
						}
						.img1 {
							width: 66px;
							height: 66px;
							margin: 20px 0;
						}
					}
					.jtico {
						transform: rotate(90deg);
						margin: 20px;
						height: 100px;
						width: 100px;
					}
				}
				.bank {
					justify-content: center;
					.zzimg {
						height: 100px;
						margin: 20px;
						img {
							height: 100px;
							width: 100px;
						}
					}
				}
			}
			.bottom {
				.btn {
					width: 300px;
					height: 100px;
					line-height: 100px;
					font-size: 30px;
					border-radius: 10px;
				}
			}
		}
	}
</style>
